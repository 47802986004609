import store from '../store'
import * as VueRouter from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/studyhalls',
        component: () => import('../layouts/landing/landingLayout.vue'),
        meta: {
            alwaysAllowed: true
        },
        children: [
            { path: 'studyhalls', name: 'studyHallsPage', component: () => import('../view/landingPage/studyHallsPage.vue') }
        ]
    },
    {
        path: '/',
        // redirect: '/signin',
        meta: {
            authRoute: true
        },
        component: () => import('../layouts/commonLayout.vue'),
        children: [
            { path: 'signin', name: 'signIn', component: () => import('../view/Auth/signinPage.vue') },
            { path: 'signup', name: 'signUp', component: () => import('../view/Auth/signupPage.vue') },
            { path: 'forgot-password', name: 'forgotPassword', component: () => import('../view/Auth/forgotPasswordPage.vue') },
            { path: 'signup-success', name: 'signupRedirectPage', component: () => import('../view/Auth/signupRedirectPage.vue') },
            { path: 'verify-emailmessage', name: 'verifyEmailMessagePage', component: () => import('../view/Auth/verifyEmailMessagePage.vue') },
            { path: 'reset-password', name: 'resetPasswordPage', component: () => import('../view/Auth/resetPasswordPage.vue') },
            { path: 'password-change-success', name: 'passwordChangeSuccessPage', component: () => import('../view/Auth/passwordChangeSuccessPage.vue') },
            { path: 'auth/verify/:key', name: 'verifyEmailPage', component: () => import('../view/Auth/verifyEmailPage.vue') }
        ]
    },
    {
        path: '/',
        component: () => import('../layouts/commonLayoutWhite.vue'),
        meta: {
            alwaysAllowed: true
        },
        children: [
            { path: 'terms-of-use', name: 'termsOfUse', component: () => import('../view/termsOfUsePage.vue') },
            { path: 'privacy-policy', name: 'privacyPolicy', component: () => import('../view/privacyPolicyPage.vue') },
        ]
    },
    {
        path: '/admin',
        meta: {
            requiresAuth: true,
            isAdmin: true
        },
        component: () => import('../layouts/admin/adminLayout.vue'),
        children: [
            { path: 'dashboard', name: 'adminDashboardPage', component: () => import('../view/Admin/adminDashboardPage.vue') },
            
            //User Module
            { path: 'userslist', name: 'adminUserListPage', component: () => import('../view/Admin/users/adminUserListPage.vue') },
            { path: 'userslist/add', name: 'adminUserAddPage', component: () => import('../view/Admin/users/adminUserAddPage.vue') },
            { path: 'userslist/edit/:id', name: 'adminUserEditPage', component: () => import('../view/Admin/users/adminUserEditPage.vue') },
            
            //StudyHalls Module
            { path: 'studyhalls', name: 'adminStudyHallsPage', component: () => import('../view/Admin/studyhalls/adminStudyHallsPage.vue') },
            { path: 'studyhalls/add', name: 'adminStudyHallsAddPage', component: () => import('../view/Admin/studyhalls/adminStudyHallsAddPage.vue') },
            { path: 'studyhalls/edit/:id', name: 'adminStudyHallsEditPage', component: () => import('../view/Admin/studyhalls/adminStudyHallsEditPage.vue') },
            { path: 'studyhalls/enrolled-students/:id', name: 'adminStudyHallsEnrolledStudents', component: () => import('../view/Admin/studyhalls/adminStudyHallsEnrolledStudents.vue') },
            
            //Sessions Module
            { path: 'sessions', name: 'adminSessionsPage', component: () => import('../view/Admin/adminSessionsPage.vue') },
            
            //Subjects Module
            { path: 'subjects', name: 'adminSubjectsPage', component: () => import('../view/Admin/adminSubjectsPage.vue') },
            
            //Schools Module
            { path: 'schools', name: 'adminSchoolsPage', component: () => import('../view/Admin/adminSchoolsPage.vue') },

            //Admin User Profile Page
            { path: 'profile', name: 'adminUserProfilePage', component: () => import('../view/Admin/adminUserProfilePage.vue') },

            //Admin Change Password Page
            { path: 'password-update', name: 'adminChangePasswordPage', component: () => import('../view/Admin/adminChangePasswordPage.vue') },
            { path: 'terms-of-use', name: 'adminTermsOfUse', component: () => import('../view/termsOfUsePage.vue') },
            { path: 'privacy-policy', name: 'adminPrivacyPolicy', component: () => import('../view/privacyPolicyPage.vue') },
        ]
    },
    {
        path: '/organization',
        meta: {
            requiresAuth: true,
            isOrganization: true
        },
        component: () => import('../layouts/organization/organizationLayout.vue'),
        children: [
            { path: 'dashboard', name: 'organizationDashboardPage', component: () => import('../view/Organization/organizationDashboardPage.vue') },
            
            //User Module
            { path: 'userslist', name: 'organizationUserListPage', component: () => import('../view/Organization/users/organizationUserListPage.vue') },
            { path: 'userslist/add', name: 'organizationUserAddPage', component: () => import('../view/Organization/users/organizationUserAddPage.vue') },
            { path: 'userslist/edit/:id', name: 'organizationUserEditPage', component: () => import('../view/Organization/users/organizationUserEditPage.vue') },

            //Sessions Module
            { path: 'sessions', name: 'organizationSessionsPage', component: () => import('../view/Organization/organizationSessionsPage.vue') },
            //Trainer User Profile Page
            { path: 'profile', name: 'organizationUserProfilePage', component: () => import('../view/Organization/organizationUserProfilePage.vue') },

            //Trainer Change Password Page
            { path: 'password-update', name: 'organizationChangePasswordPage', component: () => import('../view/Organization/organizationChangePasswordPage.vue') },
            { path: 'terms-of-use', name: 'organizationTermsOfUse', component: () => import('../view/termsOfUsePage.vue') },
            { path: 'privacy-policy', name: 'organizationPrivacyPolicy', component: () => import('../view/privacyPolicyPage.vue') },
        ]
    },
    {
        path: '/portal',
        meta: {
            requiresAuth: true,
            isTrainee: true
        },
        component: () => import('../layouts/student/studentLayout.vue'),
        children: [
            { path: 'dashboard', name: 'traineeDashboardPage', component: () => import('../view/Trainee/traineeDashboardPage.vue') },
            { path: 'searchstudyhall', name: 'traineeFindStudyhallPage', component: () => import('../view/Trainee/traineeFindStudyhallPage.vue') },
            { path: 'studyhalldata/:id', name: 'viewMoreDetailStudyHallTrainer', component: () => import('../view/Trainee/viewMoreDetailStudyHallTrainer.vue') },
            { path: 'profile', name: 'traineeUserProfilePage', component: () => import('../view/Trainee/traineeUserProfilePage.vue') },
            { path: 'subscription', name: 'traineeSubscriptionPage', component: () => import('../view/Trainee/traineeSubscriptionPage.vue') },
            { path: 'password-update', name: 'traineeChangePasswordPage', component: () => import('../view/Trainee/traineeChangePasswordPage.vue') },
            { path: 'terms-of-use', name: 'studentTermsOfUse', component: () => import('../view/termsOfUsePage.vue') },
            { path: 'privacy-policy', name: 'studentPrivacyPolicy', component: () => import('../view/privacyPolicyPage.vue') },
        ]
    },
    {
        path: '/trainer',
        meta: {
            requiresAuth: true,
            isTrainer: true
        },
        component: () => import('../layouts/trainer/trainerLayout.vue'),
        children: [
            // { path: 'dashboard', name: 'trainerDashboardPage', component: () => import('../view/Trainer/trainerDashboardPage.vue') },
            { path: 'studyhalls', name: 'trainerStudyHallsPage', component: () => import('../view/Trainer/trainerStudyHallsPage.vue') },
            //Sessions Module
            { path: 'sessions', name: 'trainerSessionsPage', component: () => import('../view/Trainer/trainerSessionsPage.vue') },
            //Trainer User Profile Page
            { path: 'profile', name: 'trainerUserProfilePage', component: () => import('../view/Trainer/trainerUserProfilePage.vue') },

            //Trainer Change Password Page
            { path: 'password-update', name: 'trainerChangePasswordPage', component: () => import('../view/Trainer/trainerChangePasswordPage.vue') },
            { path: 'terms-of-use', name: 'trainerTermsOfUse', component: () => import('../view/termsOfUsePage.vue') },
            { path: 'privacy-policy', name: 'trainerPrivacyPolicy', component: () => import('../view/privacyPolicyPage.vue') },
        ]
    },
    {
        path: '/cancel-payment',
        name: 'cancelPayment',
        meta: {
            requiresAuth: true,
            routePayment: true
        },
        component: () => import('../view/cancelPaymentPage.vue')
    },
    {
        path: '/success-payment',
        name: 'successPayment',
        meta: {
            requiresAuth: true,
            routePayment: true
        },
        component: () => import('../view/successPaymentPage.vue')
    },
    {
        path: '/cancel-subscription-payment',
        name: 'cancelSubscriptionPayment',
        meta: {
            requiresAuth: true,
            routePayment: true
        },
        component: () => import('../view/cancelSubscriptionPaymentPage.vue')
    },
    {
        path: '/success-subscription-payment',
        name: 'successSubscriptionPayment',
        meta: {
            requiresAuth: true,
            routePayment: true
        },
        component: () => import('../view/successSubscriptionPaymentPage.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            requiresAuth: true,
            isLoggedOut: true
        },
        component: () => { return false },
    }
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
  });
  
export default router

router.beforeEach((to, from, next) => {
    const alwaysAllowed = to.matched.some(record => record.meta.alwaysAllowed)
    const authRoute = to.matched.some(record => record.meta.authRoute)
    const userAuth = store.getters['auth/user']
    const requireAuth = to.matched.some(record => record.meta.requiresAuth)
    const routePayment = to.matched.some(record => record.meta.routePayment)
    const isLoggedOut = to.matched.some(record => record.meta.isLoggedOut)
    const isAdmin = to.matched.some(record => record.meta.isAdmin)
    const isTrainee = to.matched.some(record => record.meta.isTrainee)
    const isTrainer = to.matched.some(record => record.meta.isTrainer)
    const isOrganization = to.matched.some(record => record.meta.isOrganization)
    if (requireAuth && isLoggedOut) {
        store.dispatch('auth/signOut')
        next({name: 'signIn'})
    }
    if(alwaysAllowed && userAuth.is_trainee) {
        next({name: 'traineeFindStudyhallPage'})
    }
    if (alwaysAllowed) {
        next()
    } else if (authRoute && !requireAuth && !userAuth.is_superuser && !userAuth.is_trainee && !userAuth.is_trainer && !userAuth.is_org_admin) {
        next()
    } else {
        if (requireAuth && userAuth.is_superuser && isAdmin) {
            next()
        } else if (requireAuth && userAuth.is_trainee && isTrainee) {
            next()
        } else if (requireAuth && userAuth.is_trainer && isTrainer) {
            next()
        } else if (requireAuth && userAuth.is_org_admin && isOrganization) {
            next()
        } else if (requireAuth && routePayment) {
            next()
        } else if (!requireAuth && userAuth.is_superuser) {
            next({name: 'adminDashboardPage'})
        } else if (!requireAuth && userAuth.is_trainee) {
            next({name: 'traineeDashboardPage'})
        } else if (!requireAuth && userAuth.is_trainer) {
            next({name: 'trainerStudyHallsPage'})
        } else if (!requireAuth && userAuth.is_org_admin) {
            next({name: 'organizationDashboardPage'})
        } else if (requireAuth && (!userAuth.is_trainer || !userAuth.is_trainee || !userAuth.is_superuser || !userAuth.is_org_admin)) {
            next({name: 'signIn'})
        } else {
            next()
        }
    }
})