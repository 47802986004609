import store from '../../store'
import axios from 'axios'
import api from '../../helpers/endpoints'


const state = {
    sessions: null,
    studyhalls: null,
    students: null
}

const getters = {
    getSessions (state) {
        return state.sessions
    },
    getStudyhalls (state) {
        return state.studyhalls
    },
    getStudents (state) {
        return state.students
    },
}

const actions = {
    async saveStudyhalls ({commit}, payload) {
        commit('SET_STUDYHALLS', payload)
    },
    async saveStudents ({commit}, payload) {
        commit('SET_STUDENTS', payload)
    },
    async saveSessions ({commit}, payload) {
        commit('SET_SESSIONS', payload)
    },
    async addStudentToStudyhall (_, payload) {
        return axios.post(api.TRAINEE_SESSION, payload).then((response) => {
            console.log('add student to studyhall: ', response)
            return {
                'status': true,
                'code': 200,
                'data': response
            }
          }).catch(err => {
            if (err.message === 'Network Error' || err.response.status === 401) {
              store.dispatch('auth/signOut')
                .then(() => this.$router.push('/signin'))
                .catch(err => console.log(err))
            } else if (err.response.status === 500) {
              console.log(err)
            }
          })
    },
    async removeStudentFromStudyhall (_, payload) {
        return axios.delete(`${api.TRAINEE_SESSION}${payload}/`).then((response) => {
          return {
            'status': 204,
            'data': response.data
          }
        }).catch(err => {
          if (err.message === 'Network Error' || err.response.status === 401) {
            store.dispatch('auth/signOut')
              .then(() => this.$router.push('/signin'))
              .catch(err => console.log(err))
          } else if (err.response.status === 500) {
            console.log(err)
          }
        })
      },
    async destroySessionsStats ({commit}) {
        commit('RESET_SESSIONS')
    }
}

const mutations = {
    SET_SESSIONS (state, payload) {
        state.sessions = payload
    },
    SET_STUDYHALLS (state, payload) {
        state.studyhalls = payload
    },
    SET_STUDENTS (state, payload) {
        state.students = payload
    },
    RESET_SESSIONS (state) {
        state.sessions = null
        state.studyhalls = null
        state.students = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }