import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import dashboard from './dashboard'
import users from './users'
import studyhalls from './studyhalls'
import sessions from './sessions'
import subjects from './subjects'
import schools from './schools'
import studentdashboard from './studentdashboard'
import studentfindstudyhall from './studentfindstudyhall'
import organizationusers from './organizationusers'
import landingpage from './landingpage'


const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    auth,
    dashboard,
    users,
    studyhalls,
    sessions,
    subjects,
    schools,
    studentdashboard,
    studentfindstudyhall,
    organizationusers,
    landingpage
  },
  strict: debug,
  plugins: debug ? [createLogger(), createPersistedState()] : [createPersistedState()]
})