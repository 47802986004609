function LoadUser () {
    this.first_name = null,
    this.last_name = null,
    this.email = null,
    this.username = null,
    this.parentemail = null,
    this.is_superuser = null,
    this.is_trainee = null,
    this.is_trainer = null,
    this.is_parent = null,
    this.is_org_admin = null,
    this.is_org_student = null,
    this.parent_email = null,
    this.parent_first_name = null,
    this.parent_last_name = null
    this.auth_token = null
    this.school = null
}

export { LoadUser }