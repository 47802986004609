import axios from 'axios'
import store from '../store'


axios.interceptors.request.use(async (request) => {
    const token = await store.getters['auth/userAuthToken'];
    if (token) {
        // request.headers['Content-type'] = `application/json`;
        request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
})