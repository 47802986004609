import store from '../../store'
import axios from 'axios'
import api from '../../helpers/endpoints'


const state = {
    dashboard: null
}

const getters = {
    getDashboardStats (state) {
        return state.dashboard
    }
}

const actions = {
    async fetchDashboardStats ({commit}) {
        return axios
        .get(api.DASHBOARD)
        .then((resp) => {
            commit('SET_DASHBOARD', resp.data)
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => this.$router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
    },
    async destroyDashboardStats ({commit}) {
        commit('RESET_DASHBOARD')
    }
}

const mutations = {
    SET_DASHBOARD (state, payload) {
        state.dashboard = payload
    },
    RESET_DASHBOARD (state) {
        state.dashboard = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }