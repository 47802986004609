import store from '../../store'
import { LoadUser } from '../../classes/user'
import axios from 'axios'
import api from '../../helpers/endpoints'


const state = {
  user: new LoadUser(),
  uid: null
}

// getters
const getters = {
    uid (state) {
        return state.uid
    },
    user (state) {
        return state.user
    },
    userUsername (state) {
        return state.user.username || ''
    },
    userFirstname (state) {
        return state.user.first_name || ''
    },
    userLastname (state) {
        return state.user.last_name || ''
    },
    userEmail (state) {
        return state.user.email || ''
    },
    userIsTrainer (state) {
        return state.user.is_trainer || ''
    },
    userIsTrainee (state) {
        return state.user.is_trainee || ''
    },
    userIsAdmin (state) {
        return state.user.is_superuser || ''
    },
    userIsOrganizationAdmin (state) {
        return state.user.is_org_admin || ''
    },
    userIsOrganizationStudent (state) {
        return state.user.is_org_student || ''
    },
    userAuthToken (state) {
        return state.user.auth_token || ''
    },
    userSchool (state) {
        return state.user.school || ''
    }
}

// actions
const actions = {
    async signIn ({commit}, payload) {
        let username = payload.email
        let password = payload.password
        return axios
        .post(api.AUTH_URL, { email: username, password: password })
        .then((resp) => {
          const token = resp.data.access
          const user = resp.data.user
          user.auth_token = token
          commit('SET_USER', user)
          commit('set_uid', user.id)
          return {
            'status': true,
            'code': 200,
            'data': user
          }
        })
        .catch((err) => {
          if (err.message === 'Network Error') {
            this.network_error = true
            return {
                'error_type': 'network_error',
                'status': false,
                'code': 511
            }
          }
          if (err.response.status === 401) {
            return {
                'error_type': 'unauthorized',
                'status': false,
                'code': 401
            }
          }
          if (err.response.status === 500) {
            this.backendserver = true
            return {
                'error_type': 'backendserver',
                'status': false,
                'code': 500
            }
          }
          commit('SET_USER', {})
        })
    },
    async signOut ({ commit }) {
        commit('SET_USER', {})
        store.dispatch('dashboard/destroyDashboardStats')
        store.dispatch('users/destroyUserStats')
        store.dispatch('studyhalls/destroyStudyHallStats')
        store.dispatch('sessions/destroySessionsStats')
        store.dispatch('subjects/destroySubjectsStats')
        store.dispatch('schools/destroySchoolStats')
        store.dispatch('studentdashboard/destroyStudenDashboardStats')
        store.dispatch('studentfindstudyhall/destroyFindStudyHallStats')
        store.dispatch('organizationusers/destroyDashboardStats')
        store.dispatch('landingpage/destroyStudyHall')
    }
}

// mutations
const mutations = {
    SET_USER (state, payload) {
        let user = payload
        state.user.first_name = user.first_name
        state.user.last_name = user.last_name 
        state.user.email = user.email
        state.user.username = user.username
        state.user.parentemail = user.parentemail
        state.user.is_superuser = user.is_superuser
        state.user.is_trainee = user.is_trainee
        state.user.is_trainer = user.is_trainer
        state.user.is_org_admin = user.is_org_admin
        state.user.is_org_student = user.is_org_student
        state.user.is_parent = user.is_parent 
        state.user.parent_email = user.parent_email
        state.user.parent_first_name = user.parent_first_name
        state.user.parent_last_name =user.parent_last_name
        state.user.auth_token =user.auth_token
        state.user.school =user.school
    },
    set_uid (state, payload) {
        state.uid = payload
    },
    set_username (state, payload) {
        let username = payload
        state.user.username = username
    },
    set_firstname (state, payload) {
        let firstName = payload
        state.user.first_name = firstName
    },
    set_lastname (state, payload) {
        let lastName = payload
        state.user.last_name = lastName
    },
    set_email (state, payload) {
        let email = payload
        state.user.email = email
    },
    set_is_trainer (state, payload) {
        let isTrainer = payload
        state.user.is_trainer = isTrainer
    },
    set_is_trainee (state, payload) {
        let isTrainee = payload
        state.user.is_trainee = isTrainee
    },
    set_is_admin (state, payload) {
        let isAdmin = payload
        state.user.is_superuser = isAdmin
    },
    set_is_organization_admin (state, payload) {
        let isOrganizationAdmin = payload
        state.user.is_org_admin = isOrganizationAdmin
    },
    set_is_organization_student (state, payload) {
        let isOrganizationStudent = payload
        state.user.is_org_student = isOrganizationStudent
    },
    set_auth_token (state, payload) {
        let authToken = payload
        state.user.auth_token = authToken
    },
    set_school (state, payload) {
        let school = payload
        state.user.school = school
    },
    RESET_USER (state) {
        state.user = new LoadUser()
        state.uid = null
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}