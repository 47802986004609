// import store from '../../store'
// import axios from 'axios'
// import api from '../../helpers/endpoints'


const state = {
    listStudyhalls: null,
    traineesessions: null
}

const getters = {
    getListStudyHalls (state) {
        return state.listStudyhalls
    },
    getListTraineeSessions (state) {
        return state.traineesessions
    },
}

const actions = {
    async saveListStudyhalls ({commit}, payload) {
        commit('SET_LIST_STUDYHALL', payload)
    },
    async saveListTraineeSessions ({commit}, payload) {
        commit('SET_LIST_TraineeSessions', payload)
    },
    async destroyStudenDashboardStats ({commit}) {
        commit('RESET_STUDENTDASHBOARD')
    }
}

const mutations = {
    SET_LIST_STUDYHALL (state, payload) {
        state.listStudyhalls = payload
    },
    SET_LIST_TraineeSessions (state, payload) {
        state.traineesessions = payload
    },
    RESET_STUDENTDASHBOARD (state) {
        state.listStudyhalls = null
        state.traineesessions = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }