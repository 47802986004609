
const state = {
    guestSubscribe: false,
    studyhall: null
}

const getters = {
    getStudyhalls (state) {
        return state.studyhall
    },
    getGuestSubscribe (state) {
        return state.guestSubscribe
    }
}

const actions = {
    async saveStudyhall ({commit}, payload) {
        commit('SET_STUDYHALL', payload)
    },
    async destroyStudyHall ({commit}) {
        commit('RESET_STUDYHALL_MODULE')
    }
}

const mutations = {
    SET_STUDYHALL (state, payload) {
        state.guestSubscribe = true
        state.studyhall = payload
    },
    RESET_STUDYHALL_MODULE (state) {
        state.guestSubscribe = false
        state.studyhall = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}