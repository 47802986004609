import store from '../../store'
import axios from 'axios'
import api from '../../helpers/endpoints'


const state = {
    studyhalls: null,
    studentList: null,
    studyhallStudentList: null
}

const getters = {
    getStudyhalls (state) {
        return state.studyhalls
    },
    getStudentList (state) {
      return state.studentList
    },
    getStudyHallStudentList (state) {
      return state.studyhallStudentList
    },
}

const actions = {
    async saveStudyhalls ({commit}, payload) {
        commit('SET_STUDYHALLS', payload)
    },
    async saveStudentList ({commit}, payload) {
        commit('SET_STUDENTLIST', payload)
    },
    async saveStudyhallStudentList ({commit}, payload) {
        commit('SET_STUDYHALLSTUDENTLIST', payload)
    },
    async subscribeStudent (_, payload) {
      return axios.post(api.SUBSCRIBE, payload).then((response) => {
        console.log('subscribe student: ', response)
        return {
            'status': true,
            'code': 200,
            'data': response
        }
      }).catch(err => {
        if (err.message === 'Network Error' || err.response.status === 401) {
          store.dispatch('auth/signOut')
            .then(() => this.$router.push('/signin'))
            .catch(err => console.log(err))
        } else if (err.response.status === 500) {
          console.log(err)
        }
      })
    },
    async fetchSubjects () {
        return axios
        .get(api.BUSINESSGROUP)
        .then((resp) => {
            let data = []
            resp.data.results.map((val) => {
                data.push({ value: val.id, text: val.name })
            })
            return {
                'status': true,
                'code': 200,
                'data': data
            }
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => this.$router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
    },
    async fetchTrainersBySubject (_, payload) {
        return axios
        .get(`${api.TRAINER_SUBJECT}?subject=[${payload}]`)
        .then((resp) => {
            let data = []
            resp.data.map((val) => {
                data.push({ value: val.trainer_details.id, text: val.trainer_details.first_name + ' ' + val.trainer_details.last_name })
            })
            return {
                'status': true,
                'code': 200,
                'data': data
            }
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => this.$router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
    },
    async createStudyHall (_, payload) {
        return axios.post(api.CREATE_STUDYHALL, payload).then((response) => {
            if (response.status === 200 && response.statusText === 'OK') {
                return {
                  'status': response.status,
                  'statusText': response.statusText
                }
            }
          }).catch((err) => {
            return {
              'status': err.response.status,
              'errorType': 'error',
              'error': true,
              'errorText': err.response.data
            }
          })
    },
    async updateStudyHall (_, payload) {
        return axios.put(`${api.CREATE_STUDYHALL}${payload.id}/`, payload.formData).then((response) => {
            if (response.status === 200 && response.statusText === 'OK') {
                return {
                  'status': response.status,
                  'statusText': response.statusText
                }
            }
          }).catch((err) => {
            return {
              'status': err.response.status,
              'errorType': 'error',
              'error': true,
              'errorText': err.response.data
            }
          })
    },
    async deleteStudyHall ({ commit }, payload) {
        return axios.delete(`${api.CREATE_STUDYHALL}${payload}/`).then((response) => {
            if (response.status === 200) {
                return {
                  'status': response.status,
                }
            }
            commit();
          }).catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
              store.dispatch('auth/signOut')
                .then(() => this.$router.push('/signin'))
                .catch(err => console.log(err))
            } else if (err.response.status === 500) {
              console.log(err)
            }
          })
    },
    async unsubscribeStudent (_, payload) {
      return axios.delete(`${api.SUBSCRIBE}${payload}/`).then((response) => {
        return {
          'status': 204,
          'data': response.data
        }
      }).catch(err => {
        if (err.message === 'Network Error' || err.response.status === 401) {
          store.dispatch('auth/signOut')
            .then(() => this.$router.push('/signin'))
            .catch(err => console.log(err))
        } else if (err.response.status === 500) {
          console.log(err)
        }
      })
    },
    async destroyStudyHallStats ({commit}) {
        commit('RESET_STUDYHALL_MODULE')
    }
}

const mutations = {
    SET_STUDYHALLS (state, payload) {
        state.studyhalls = payload
    },
    SET_STUDENTLIST (state, payload) {
        state.studentList = payload
    },
    SET_STUDYHALLSTUDENTLIST (state, payload) {
        state.studyhallStudentList = payload
    },
    RESET_STUDYHALL_MODULE (state) {
        state.studyhalls = null
        state.studentList = null
        state.studyhallStudentList = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}