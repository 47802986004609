
const BASE_URL = process.env.VUE_APP_API_URL
export const TRACKDESK_URL = process.env.VUE_APP_TRACKDESK_API_URL
export default {
 'AUTH_URL': `${BASE_URL}/api/v1/login/`,
 'USERS': `${BASE_URL}/api/v1/users/`,
 'UPDATEUSERPROFILE': `${BASE_URL}/api/v1/users/update-my-details/`,
 'BUSINESSGROUP': `${BASE_URL}/api/v1/courses/`,
 'MYPROFILE': `${BASE_URL}/api/me/`,
 'LEVEL': `${BASE_URL}/api/level/`,
 'MODULE': `${BASE_URL}/api/v1/schools/`,
 'TRAINEES': `${BASE_URL}/api/trainees/`,
 'DASHBOARD': `${BASE_URL}/api/v1/dashboard/`,
 'USERUPDATE': `${BASE_URL}/api/user_edit/`,
 'FIELD': `${BASE_URL}/api/files/`,
 'REPORTS': `${BASE_URL}/api/reports/`,
 'TRAINEE_SESSION': `${BASE_URL}/api/v1/traineesession/`,
 'REMOVETRAINEESESSION': `${BASE_URL}/api/v1/traineesession/student-delete/`,
 'BATCH': `${BASE_URL}/api/v1/trainersession/`,
 'SIGNUP': `${BASE_URL}/api/v1/signup/`,
 'PARENT_CHILDLINK': `${BASE_URL}/api/v1/linkpc/`,
 'TRAINERDASHBOARD': `${BASE_URL}/api/v1/dashboard/`,
 'TRAINER_SUBJECT': `${BASE_URL}/api/v1/trainercourse/`,
 'VERIFY_EMAIL': `${BASE_URL}/api/v1/signup/verify-email/`,
 'FILE_UPLOAD': `${BASE_URL}/api/v1/files/`,
 'STUDY_HALLS': `${BASE_URL}/api/v1/halls/`,
 'CREATE_STUDYHALL': `${BASE_URL}/api/v1/studyhall/`,
 'SUBSCRIBE': `${BASE_URL}/api/v1/subscribe/`,
 'SESSIONS': `${BASE_URL}/api/v1/sessions/`,
 'SESSIONSORG': `${BASE_URL}/api/v1/sessions/org/`,
 'CHANGEPASSWORD': `${BASE_URL}/api/v1/change_pw/`,
 'STUDYHALLWITHSUBJECT': `${BASE_URL}/api/v1/searchsubject/`,
 'PUBLIC_SUBJECTS': `${BASE_URL}/api/v1/public/subjects/`,
 'PUBLIC_STUDYHALLS': `${BASE_URL}/api/v1/public/studyhall/`,
 'ROOMS': `${BASE_URL}/api/v1/rooms/`,
 'MESSAGES': `${BASE_URL}/api/v1/messages/`,
 'ADDCREDIT': `${BASE_URL}/api/v1/paymenttoken/`,
 'CANCELCREDIT': `${BASE_URL}/api/v1/cancelpayment/`,
 'FORGOTPASSWORD': `${BASE_URL}/api/v1/forgot_pw/`,
 'RESETPASSWORD': `${BASE_URL}/api/v1/reset_pw/`,
 'SUBSCRIBEDSTUDENTLIST': `${BASE_URL}/api/v1/subscribe/students/`,
 'saveRatings': `${BASE_URL}/api/v1/ratings/`,
 'saveSessionInformation': `${BASE_URL}/api/v1/sessions/covered-need-things/`,
 'organizationUserList': `${BASE_URL}/api/v1/users/org/`,
 'SUBSCRIPTIONPLANSLIST': `${BASE_URL}/api/v1/subscription-plans/`,
 'SUBSCRIPTION': `${BASE_URL}/api/v1/subscription/`,
 'SAVECARDS': `${BASE_URL}/api/v1/save-card/`,
 'PLANDETAILS': `${BASE_URL}/api/v1/subscription/plan_details/`,
 'PLANS': `${BASE_URL}/api/v1/plan/`,
 'CREATESUBSCRIPTIONPAYPAL': `${BASE_URL}/api/v1/subscriptions/`,
 'CANCELSCRIPTIONPAYPAL': `${BASE_URL}/api/v1/subscriptions/cancel/payment/`,
 'DETAILSSCRIPTIONPAYPAL': `${BASE_URL}/api/v1/subscriptions/user/details/`,
}

// export const AUTH_URL = `${BASE_URL}/api/v1/login/`
// export const USERS = `${BASE_URL}/api/v1/users/`
// export const BUSINESSGROUP = `${BASE_URL}/api/v1/courses/`
// export const MYPROFILE = `${BASE_URL}/api/me/`
// export const LEVEL = `${BASE_URL}/api/level/`
// export const MODULE = `${BASE_URL}/api/v1/schools/`
// export const TRAINEES = `${BASE_URL}/api/trainees/`
// export const DASHBOARD = `${BASE_URL}/api/v1/dashboard/`
// export const USERUPDATE = `${BASE_URL}/api/user_edit/`
// export const FIELD = `${BASE_URL}/api/files/`
// export const REPORTS = `${BASE_URL}/api/reports/`
// export const TRAINEE_SESSION = `${BASE_URL}/api/v1/traineesession/`
// export const REMOVETRAINEESESSION = `${BASE_URL}/api/v1/traineesession/student-delete/`
// export const BATCH = `${BASE_URL}/api/v1/trainersession/`
// export const SIGNUP = `${BASE_URL}/api/v1/signup/`
// export const PARENT_CHILDLINK = `${BASE_URL}/api/v1/linkpc/`
// export const TRAINERDASHBOARD = `${BASE_URL}/api/v1/dashboard/`
// export const TRAINER_SUBJECT = `${BASE_URL}/api/v1/trainercourse/`
// export const VERIFY_EMAIL = `${BASE_URL}/api/v1/signup/verify-email/`
// export const FILE_UPLOAD = `${BASE_URL}/api/v1/files/`
// export const STUDY_HALLS = `${BASE_URL}/api/v1/halls/`
// export const CREATE_STUDYHALL = `${BASE_URL}/api/v1/studyhall/`
// export const SUBSCRIBE = `${BASE_URL}/api/v1/subscribe/`
// export const SESSIONS = `${BASE_URL}/api/v1/sessions/`
// export const CHANGEPASSWORD = `${BASE_URL}/api/v1/change_pw/`
// export const STUDYHALLWITHSUBJECT = `${BASE_URL}/api/v1/searchsubject/`
// export const PUBLIC_SUBJECTS = `${BASE_URL}/api/v1/public/subjects/`
// export const PUBLIC_STUDYHALLS = `${BASE_URL}/api/v1/public/studyhall/`
// export const ROOMS = `${BASE_URL}/api/v1/rooms/`
// export const MESSAGES = `${BASE_URL}/api/v1/messages/`
// export const ADDCREDIT = `${BASE_URL}/api/v1/paymenttoken/`
// export const CANCELCREDIT = `${BASE_URL}/api/v1/cancelpayment/`
// export const FORGOTPASSWORD = `${BASE_URL}/api/v1/forgot_pw/`
// export const RESETPASSWORD = `${BASE_URL}/api/v1/reset_pw/`
// export const SUBSCRIBEDSTUDENTLIST = `${BASE_URL}/api/v1/subscribe/students/`
