import store from '../../store'
import axios from 'axios'
import api from '../../helpers/endpoints'


const state = {
    subjects: null,
}

const getters = {
    getSubjects (state) {
        return state.subjects
    }
}

const actions = {
    async saveSubjects ({commit}, payload) {
        commit('SET_SUBJECTS', payload)
    },
    async addSubject (_, payload) {
        return axios.post(api.BUSINESSGROUP, payload).then((response) => {
            return {
                'status': true,
                'code': 200,
                'data': response
            }
          }).catch(err => {
            if (err.message === 'Network Error' || err.response.status === 401) {
              store.dispatch('auth/signOut')
                .then(() => this.$router.push('/signin'))
                .catch(err => console.log(err))
            } else if (err.response.status === 500) {
              console.log(err)
            }
          })
    },
    async updateSubject (_, payload) {
        return axios.put(`${api.BUSINESSGROUP}${payload.id}/`, payload.name).then((response) => {
            return {
                'status': true,
                'code': 200,
                'data': response
            }
          }).catch(err => {
            if (err.message === 'Network Error' || err.response.status === 401) {
              store.dispatch('auth/signOut')
                .then(() => this.$router.push('/signin'))
                .catch(err => console.log(err))
            } else if (err.response.status === 500) {
              console.log(err)
            }
          })
    },
    async removeSubject (_, payload) {
        return axios.delete(`${api.BUSINESSGROUP}${payload}/`).then((response) => {
          return {
            'status': 204,
            'data': response.data
          }
        }).catch(err => {
          if (err.message === 'Network Error' || err.response.status === 401) {
            store.dispatch('auth/signOut')
              .then(() => this.$router.push('/signin'))
              .catch(err => console.log(err))
          } else if (err.response.status === 500) {
            console.log(err)
          }
        })
      },
    async destroySubjectsStats ({commit}) {
        commit('RESET_SUBJECTS')
    }
}

const mutations = {
    SET_SUBJECTS (state, payload) {
        state.subjects = payload
    },
    RESET_SUBJECTS (state) {
        state.subjects = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }