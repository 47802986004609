import { createApp } from 'vue'
import App from './App.vue'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import VueTelInput from 'vue3-tel-input'
import VueCreditCardValidation from 'vue-credit-card-validation';
import 'vue3-tel-input/dist/vue3-tel-input.css'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import utils from './helpers/utils'
import endpoints from './helpers/endpoints'
import router from './router'
import '@/styles/styles.scss'
import store from './store'
import mitt from 'mitt';
import './helpers/axios'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }
})

const emitter = mitt();

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('DatePicker', Datepicker);
app.use(VueTelInput);
app.use(VueCreditCardValidation);
app.config.globalProperties.$utils = utils
app.config.globalProperties.$api = endpoints
app.config.globalProperties.$emitter = emitter
app.mount('#app');
