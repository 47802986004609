import axios from 'axios'
import api from '../../helpers/endpoints'

const state = {
    organizationUser: null
}

const getters = {
    getOrganizationUser (state) {
        return state.organizationUser
    }
}

const actions = {
    async saveOrganizationUser ({commit}, payload) {
        commit('SET_OrganizationUser', payload)
    },
    async createUser ({ commit }, payload) {
        let formData = new FormData()
        formData.append('file', payload.file)
        formData.append('image', payload.image)
        formData.append('username', payload.username)
        formData.append('first_name', payload.first_name)
        formData.append('last_name', payload.last_name)
        formData.append('parent_first_name', payload.parent_first_name)
        formData.append('parent_last_name', payload.parent_last_name)
        formData.append('mobile_number', payload.mobile_number)
        formData.append('email', payload.email)
        formData.append('parent_email', payload.parent_email)
        formData.append('location', payload.location)
        formData.append('gender', payload.gender)
        formData.append('password', payload.password)
        formData.append('credits', payload.credits)
        formData.append('designation', payload.designation)
        formData.append('description', payload.description)
        formData.append('experience', payload.experience)
        formData.append('award', payload.award)
        formData.append('more_bio', payload.more_bio)
        formData.append('education', payload.education)
        formData.append('is_trainer', payload.is_trainer)
        formData.append('is_trainee', payload.is_trainee)
        formData.append('is_superuser', payload.is_superuser)
        formData.append('is_org_admin', payload.is_org_admin)
        formData.append('date_of_join', payload.date_of_join)
        formData.append('school', payload.school)
        formData.append('course', payload.course)
        formData.append('is_active', payload.is_active)
        formData.append('is_org_student', payload.is_org_student)
        return axios.post(api.USERS, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        }).then((response) => {
            if (response.status === 200 && response.statusText === 'OK') {
                return {
                'status': response.status,
                'statusText': response.statusText,
                'userCreated': true
                }
            }
            commit();
        }).catch((err) => {
            if(err.response.status === 400) {
                if (err.response.data['email'] && err.response.data['email'][0] !== '') {
                return {
                    'status': err.response.status,
                    'errorType': 'email',
                    'error': true,
                    'errorText': err.response.data['email'][0]
                }
                } else if (err.response.data['password1'][0] !== '') {
                return {
                    'status': err.response.status,
                    'errorType': 'password',
                    'error': true,
                    'errorText': err.response.data['password1'][0]
                }
                } else {
                return {
                    'status': err.response.status,
                    'errorType': 'non_field_errors',
                    'error': true,
                    'errorText': err.response.data['non_field_errors'][0]
                }
                }
            }
        })
    },
    async updateUser ({ commit }, payload) {
        let formData = new FormData()
        formData.append('file', payload.formData.file)
        formData.append('image', payload.formData.image)
        formData.append('username', payload.formData.username)
        formData.append('first_name', payload.formData.first_name)
        formData.append('last_name', payload.formData.last_name)
        formData.append('parent_first_name', payload.formData.parent_first_name)
        formData.append('parent_last_name', payload.formData.parent_last_name)
        formData.append('mobile_number', payload.formData.mobile_number)
        formData.append('email', payload.formData.email)
        formData.append('parent_email', payload.formData.parent_email)
        formData.append('location', payload.formData.location)
        formData.append('gender', payload.formData.gender)
        formData.append('password', payload.formData.password)
        formData.append('credits', payload.formData.credits)
        formData.append('designation', payload.formData.designation)
        formData.append('description', payload.formData.description)
        formData.append('experience', payload.formData.experience)
        formData.append('award', payload.formData.award)
        formData.append('more_bio', payload.formData.more_bio)
        formData.append('education', payload.formData.education)
        formData.append('is_trainer', payload.formData.is_trainer)
        formData.append('is_trainee', payload.formData.is_trainee)
        formData.append('is_superuser', payload.formData.is_superuser)
        formData.append('is_org_admin', payload.formData.is_org_admin)
        formData.append('date_of_join', payload.formData.date_of_join)
        formData.append('school', payload.formData.school)
        formData.append('course', payload.formData.course)
        formData.append('is_active', payload.formData.is_active)
        formData.append('is_org_student', payload.formData.is_org_student)
        return axios.put(`${api.USERS}${payload.uid}/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        }).then((response) => {
            if (response.status === 200 && response.statusText === 'OK') {
                return {
                'status': response.status,
                'statusText': response.statusText
                }
            }
            commit();
        }).catch((err) => {
            if(err.response.status === 400) {
                if (err.response.data['email'] && err.response.data['email'][0] !== '') {
                return {
                    'status': err.response.status,
                    'errorType': 'email',
                    'error': true,
                    'errorText': err.response.data['email'][0]
                }
                } else if (err.response.data['password1'][0] !== '') {
                return {
                    'status': err.response.status,
                    'errorType': 'password',
                    'error': true,
                    'errorText': err.response.data['password1'][0]
                }
                } else {
                return {
                    'status': err.response.status,
                    'errorType': 'non_field_errors',
                    'error': true,
                    'errorText': err.response.data['non_field_errors'][0]
                }
                }
            }
        })
    },
    async deleteUser ({ commit }, payload) {
        return axios.delete(`${api.USERS}${payload}/`).then((response) => {
            if (response.status === 204) {
                return {
                'status': response.status,
                }
            }
            commit();
        }).catch((err) => {
            if(err.response.status === 400) {
                if (err.response.data['email'] && err.response.data['email'][0] !== '') {
                return {
                    'status': err.response.status,
                    'errorType': 'email',
                    'error': true,
                    'errorText': err.response.data['email'][0]
                }
                } else if (err.response.data['password1'][0] !== '') {
                return {
                    'status': err.response.status,
                    'errorType': 'password',
                    'error': true,
                    'errorText': err.response.data['password1'][0]
                }
                } else {
                return {
                    'status': err.response.status,
                    'errorType': 'non_field_errors',
                    'error': true,
                    'errorText': err.response.data['non_field_errors'][0]
                }
                }
            }
        })
    },
    async destroyDashboardStats ({commit}) {
        commit('RESET_DASHBOARD')
    }
}

const mutations = {
    SET_OrganizationUser (state, payload) {
        state.organizationUser = payload
    },
    RESET_DASHBOARD (state) {
        state.organizationUser = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }