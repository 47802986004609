import axios from 'axios'
import api from './endpoints'
import { TRACKDESK_URL } from './endpoints';
import moment from 'moment';
import store from '@/store';
import router from '@/router';

export default  {
  getText () {
    return "This is demo."
  },
  ShowText(val) {
    return val
  },
  async createUser (data) {
    const result = await axios.post(api.SIGNUP, data).then((response) => {
      if (response.status === 201 && response.statusText === 'Created') {
        return {
          'status': response.status,
          'statusText': response.statusText,
          'userCreated': true
        }
      }
    }).catch(err => {
      if(err.response.status === 400) {
        if (err.response.data['email'] && err.response.data['email'][0] !== '') {
          return {
            'status': err.response.status,
            'errorType': 'email',
            'error': true,
            'errorText': 'Student : ' + err.response.data['email'][0]
          }
        } else if (err.response.data['password1'] && err.response.data['password1'][0] !== '') {
          return {
            'status': err.response.status,
            'errorType': 'password',
            'error': true,
            'errorText': 'Student : ' + err.response.data['password1'][0]
          }
        } else {
          return {
            'status': err.response.status,
            'errorType': 'non_field_errors',
            'error': true,
            'errorText': err.response.data['non_field_errors'][0]
          }
        }
      }
    })

    return result
  },
  async requestForgotPassword (email) {
    return axios
        .post(api.FORGOTPASSWORD, { email: email })
        .then((resp) => {
          return {
            'status': true,
            'code': 200,
            'data': resp
          }
        })
        .catch((err) => {
          // SDUser matching query does not exist.
          if (err.message === 'Network Error') {
            return {
              'status': false,
              'error_type': 'network_error',
              'code': 511,
            }
          }
          if (err.response.status === 401) {
            return {
                'error_type': 'unauthorized',
                'status': false,
                'code': 401
            }
          }
          if (err.response.status === 400) {
            if (err.response.data.error === 'SDUser matching query does not exist.') {
              return {
                'error_type': 'user_not_exist',
                'status': false,
                'code': 401,
                'data': err.response.data.error
              }
            } else {
              return {
                'error_type': 'other_error',
                'status': false,
                'data': err.response.data.error
              }
            }
          }
        })
  },
  async resetForgotPassword (access_token, data) {
    axios.defaults.headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    }
    return axios
        .post(api.RESETPASSWORD, data)
        .then((resp) => {
          if (resp.status === 200 && resp.data.data === 'password changed successfully') {
            return {
              'status': true,
              'code': 200,
              'data': resp
            }
          }
        })
        .catch((err) => {
          if (err.message === 'Network Error') {
            return {
              'status': false,
              'error_type': 'network_error',
              'code': 511,
            }
          }
          if (err.response.status === 401) {
            return {
                'error_type': 'unauthorized',
                'status': false,
                'code': 401
            }
          }
        })
  },
  todayDateFormatYYYYMMDDSlash () {
    return moment(new Date()).format('YYYY/MM/DD');
  },
  todayDateFormatYYYYMMDD () {
    return moment(new Date()).format('YYYY-MM-DD');
  },
  dateFormatYYYYMMDDSlash (date) {
    return moment(date).format('YYYY/MM/DD');
  },
  dateFormatYYYYMMDD (date) {
    return moment(date).format('YYYY-MM-DD');
  },
  mommentDate (date) {
    return moment(date).toDate();
  },
  async fetchUsers (page, result = []) {
    return axios
        .get(`${api.USERS}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchUsers(page+1, result)
            } else {
              store.dispatch('users/saveUsers', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudents (page, result = []) {
    return axios
        .get(`${api.USERS}?page=${page}&is_student=true`)
        .then((resp) => {
            resp.data.results.map((val) => {
              let objStudent = {
                value: val.id,
                text: val.first_name + ' ' + val.last_name
              }
              result.push(objStudent)

            })
            if (resp.data.next !== null) {
              this.fetchStudents(page+1, result)
            } else {
              store.dispatch('studyhalls/saveStudentList', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudentListByStudyhall (id) {
    return axios
        .get(`${api.SUBSCRIBEDSTUDENTLIST}${id}/`)
        .then((resp) => {
          if (resp.status === 200) {
            let listStudent = []
            for (let j in resp.data.results) {
              let objResult = {
                subscribe_id: resp.data.results[j].subscribe_id,
                student_id: resp.data.results[j].id,
                name: resp.data.results[j].trainee_first_name + ' ' + resp.data.results[j].trainee_last_name,
                email: resp.data.results[j].trainee_email
              }
              listStudent.push(objResult)
            }
            store.dispatch('studyhalls/saveStudyhallStudentList', listStudent)
            return {
                'status': true,
                'code': 200,
                'data': listStudent
            }
          }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudyhallName (id) {
    return axios
        .get(`${api.CREATE_STUDYHALL}${id}/`)
        .then((resp) => {
          if (resp.status === 200) {
            let object = {'name': resp.data.name}
            return {
                'status': true,
                'code': 200,
                'data': object
            }
          }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudyHalls (page, result = []) {
    return axios
        .get(`${api.CREATE_STUDYHALL}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchStudyHalls(page+1, result)
            } else {
              if (result.length !== 0) {
                let today = []
                let upcoming = []
                let completed = []
                result.map((val) => {
                  if (val.trainers_info[0].status === 'today') {
                    today.push({
                      id: val.id,
                      name: val.name,
                      start_time: val.trainers_info[0].start_time,
                      total_info: val
                    })
                  } else if (val.trainers_info[0].status === 'upcoming') {
                    upcoming.push({
                      id: val.id,
                      name: val.name,
                      start_time: val.trainers_info[0].start_time,
                      total_info: val
                    })
                  } else if (val.trainers_info[0].status === 'completed') {
                    completed.push({
                      id: val.id,
                      name: val.name,
                      start_time: val.trainers_info[0].start_time,
                      total_info: val
                    })
                  }
                })

                let studyhalls = {
                  'today': today,
                  'upcoming': upcoming,
                  'completed': completed
                }
                store.dispatch('studyhalls/saveStudyhalls', studyhalls)
                return {
                    'status': true,
                    'code': 200,
                    'data': studyhalls
                }
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudyHallsForTrainer (page, result = []) {
    return axios
        .get(`${api.CREATE_STUDYHALL}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (result.length !== 0) {
              let today = []
              let upcoming = []
              let completed = []
              result.map((val) => {
                if (val.trainers_info[0].status === 'today') {
                  today.push({
                    id: val.id,
                    name: val.name,
                    start_time: val.trainers_info[0].start_time,
                    total_info: val,
                    meeting_id: val.meeting_id
                  })
                } else if (val.trainers_info[0].status === 'upcoming') {
                  upcoming.push({
                    id: val.id,
                    name: val.name,
                    start_time: val.trainers_info[0].start_time,
                    total_info: val,
                    meeting_id: val.meeting_id
                  })
                } else if (val.trainers_info[0].status === 'completed') {
                  completed.push({
                    id: val.id,
                    name: val.name,
                    start_time: val.trainers_info[0].start_time,
                    total_info: val,
                    meeting_id: val.meeting_id
                  })
                }
              })

              let studyhalls = {
                'today': today,
                'upcoming': upcoming,
                'completed': completed
              }
              store.dispatch('studyhalls/saveStudyhalls', studyhalls)
              return {
                  'status': true,
                  'code': 200,
                  'data': studyhalls
              }
            }
        })
        .catch((err) => {
          console.log("err: ", err)
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchSessionsForTrainer (page, result = []) {
    return axios
        .get(`${api.SESSIONS}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchSessionsForTrainer(page+1, result)
            } else {
              if (result.length !== 0) {
                let today = []
                let upcoming = []
                let completed = []
                result.map((val) => {
                  if (val.status.toLowerCase() === 'today') {
                    today.push({
                      'studyhall_name': val.study_hall_name.studyhall,
                      'name': `${val.trainee_first_name} ${val.trainee_last_name}`,
                      'notes': val.notes,
                      'sessionid': val.session_id,
                      'date': val.datetime.date.split(' ')[0],
                      'time_booked': val.datetime.time,
                      'links': val.links,
                      'covered_things': val.covered_things,
                      'need_things': val.need_things
                    })
                  } else if (val.status.toLowerCase() === 'upcoming') {
                    upcoming.push({
                      'studyhall_name': val.study_hall_name.studyhall,
                      'name': `${val.trainee_first_name} ${val.trainee_last_name}`,
                      'notes': val.notes,
                      'sessionid': val.session_id,
                      'date': val.datetime.date.split(' ')[0],
                      'time_booked': val.datetime.time,
                      'links': val.links,
                      'covered_things': val.covered_things,
                      'need_things': val.need_things
                    })
                  } else {
                    completed.push({
                      'studyhall_name': val.study_hall_name.studyhall,
                      'name': `${val.trainee_first_name} ${val.trainee_last_name}`,
                      'notes': val.notes,
                      'sessionid': val.session_id,
                      'date': val.datetime.date.split(' ')[0],
                      'time_booked': val.datetime.time,
                      'links': val.links,
                      'covered_things': val.covered_things,
                      'need_things': val.need_things
                    })
                  }
                })
  
                let sessions = {
                  'today': today,
                  'upcoming': upcoming,
                  'completed': completed
                }
                store.dispatch('sessions/saveSessions', sessions)
                return {
                    'status': true,
                    'code': 200,
                    'data': sessions
                }
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchSessionsForOrganiztion (page, orgid, result = []) {
    return axios
        .get(`${api.SESSIONSORG}${orgid}/?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchSessionsForOrganiztion(page+1, orgid, result)
            } else {
              if (result.length !== 0) {
                let data = []
                result.map((val) => {
                  data.push({
                    'studyhall_name': val.study_hall_name.studyhall,
                    'name': `${val.trainee_first_name} ${val.trainee_last_name}`,
                    'tutorname': `${val.trainer_first_name} ${val.trainer_last_name}`,
                    'notes': val.notes,
                    'sessionid': val.session_id,
                    'date': val.datetime.eng,
                    'time_booked': val.datetime.time
                  })
                })
                return {
                    'status': true,
                    'code': 200,
                    'data': data
                }
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async getByIDUser (id) {
    return await axios
        .get(`${api.USERS}${id}`)
        .then((resp) => {
            return resp.data
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async getStudyHallByID (id) {
    return await axios
        .get(`${api.CREATE_STUDYHALL}${id}`)
        .then((resp) => {
            console.log('resp.data: ', resp.data)
            return resp.data
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudentsSessions (page, result = []) {
    return axios
        .get(`${api.USERS}?page=${page}&is_student=true`)
        .then((resp) => {
            resp.data.results.map((val) => {
              let objStudent = {
                value: val.id,
                text: val.first_name + ' ' + val.last_name
              }
              result.push(objStudent)

            })
            if (resp.data.next !== null) {
              this.fetchStudentsSessions(page+1, result)
            } else {
              store.dispatch('sessions/saveStudents', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudyHallsSessions (page, result = []) {
    return axios
        .get(`${api.CREATE_STUDYHALL}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchStudyHallsSessions(page+1, result)
            } else {
              store.dispatch('sessions/saveStudyhalls', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchIndividualSession (page, result = []) {
    return axios.get(`${api.SESSIONS}?page=${page}`).then((response) => {
      let data = response.data.results
      // let individualsessions = []
      for (let j in data) {
        result.push({
          session_id: data[j].id,
          studyhall_name: data[j].study_hall_name.studyhall,
          ratings: data[j].student_rating.rating === undefined ? 0 : data[j].student_rating.rating,
          comment: data[j].student_rating.comment === undefined ? '' : data[j].student_rating.comment,
          date: data[j].datetime.date.split(' ')[0],
          tutor: data[j].trainer_first_name + ' ' + data[j].trainer_last_name,
          time_booked: data[j].datetime.time,
          student: data[j].trainee_first_name + ' ' + data[j].trainee_last_name,
          covered_things: data[j].covered_things,
          need_things: data[j].need_things,
          notes: data[j].notes,
          links: data[j].links
        })
      }
      if (response.data.next !== null) {
        this.fetchIndividualSession(page+1, result)
      } else {
        store.dispatch('sessions/saveSessions', result)
        return {
            'status': true,
            'code': 200,
            'data': result
        } 
      }
    }).catch(err => {
      console.log('err Session: ', err)
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      }
    })
  },
  async fetchSubjects (page, result = []) {
    return axios
        .get(`${api.BUSINESSGROUP}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchSubjects(page+1, result)
            } else {
              store.dispatch('subjects/saveSubjects', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchSchools (page, result = []) {
    return axios
        .get(`${api.MODULE}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchSchools(page+1, result)
            } else {
              store.dispatch('schools/saveSchools', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async updateUserProfileDetails(payload) {
    let formData = new FormData()
    formData.append('image', payload.formData.file)
    formData.append('image_url', payload.formData.image)
    formData.append('first_name', payload.formData.first_name)
    formData.append('last_name', payload.formData.last_name)
    formData.append('parent_first_name', payload.formData.parent_first_name)
    formData.append('parent_last_name', payload.formData.parent_last_name)
    formData.append('mobile_number', payload.formData.mobile_number)
    formData.append('email', payload.formData.email)
    formData.append('parent_email', payload.formData.parent_email)
    formData.append('description', payload.formData.description)

      return axios.put(`${api.UPDATEUSERPROFILE}${payload.uid}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
          if (response.status === 200 && response.statusText === 'OK') {
              return {
                'status': response.status,
                'statusText': response.statusText
              }
          }
        }).catch((err) => {
          if(err.response.status === 400) {
              if (err.response.data.data['email'] && err.response.data.data['email'][0] !== '') {
                return {
                  'status': err.response.status,
                  'errorType': 'email',
                  'error': true,
                  'errorText': err.response.data.data['email'][0]
                }
              } else if (err.response.data.data['mobile_number'] && err.response.data.data['mobile_number'][0] !== '') {
                return {
                  'status': err.response.status,
                  'errorType': 'mobile',
                  'error': true,
                  'errorText': err.response.data.data['mobile_number'][0]
                }
              } else if (err.response.data.data['gender'] && err.response.data.data['gender'][0] !== '') {
                return {
                  'status': err.response.status,
                  'errorType': 'gender',
                  'error': true,
                  'errorText': err.response.data.data['gender'][0]
                }
              } else if (err.response.data.data['parent_email'] && err.response.data.data['parent_email'][0] !== '') {
                return {
                  'status': err.response.status,
                  'errorType': 'parent_email',
                  'error': true,
                  'errorText': err.response.data.data['parent_email'][0]
                }
              } else {
                if (err.response.data.data['non_field_errors']) {
                  return {
                    'status': err.response.status,
                    'errorType': 'non_field_errors',
                    'error': true,
                    'errorText': err.response.data.data['non_field_errors'][0]
                  }
                }
              }
            }
        })
  },
  async updateUserPassword (payload) {
    return axios.put(api.CHANGEPASSWORD + `${payload.uid}` + '/', payload.formData)
    .then(response => {
      if (response.status === 200 && response.data.data === 'password changed successfully') {
        return {
          'status': response.status,
          'data': response.data.data
        }
      } else {
        return {
          'status': response.status,
          'data': response.data.data
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 400) {
        return {
          'status': err.response.status,
          'data': err.response.data
        }
      } else if (err.response.status === 500) {
        console.log(err)
      }
    })
  },
  async fetchStudentStudyhalls () {
    return axios
        .get(`${api.CREATE_STUDYHALL}`)
        .then((resp) => {
            let result = []
            resp.data.results.map((val) => {
              if (val.subscribed === true && val.trainers_info.length !== 0) {
                result.push(val)
              }
            })
            // store.dispatch('studentdashboard/saveListStudyhalls', result)
            return {
                'status': true,
                'code': 200,
                'data': result
            }
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async getBatches (page, result = []) {
    return axios
        .get(`${api.SESSIONS}?page=${page}`)
        .then(async (response) => {
          response.data.results.map((val) => {
            result.push(val)
          })
          if (response.data.next !== null) {
              await this.getBatches(page+1, result)
            } else {
              result.sort((a, b) => Number(new Date(a.datetime.date)) - Number(new Date(b.datetime.date)))
              let completedstudyhallsduplicate = []
              let completedstudyhalls = []
              for (let k in result) {
                completedstudyhallsduplicate.push(result[k])
                completedstudyhalls.push({ meeting_id: result[k].meeting_id,
                  name: result[k].study_hall_name['studyhall'],
                  trainers_info: [
                    {
                      awards: result[k].awards,
                      education: result[k].education,
                      time_schedule_format: result[k].datetime.time,
                      image: result[k].image,
                      weekdays: result[k].datetime.eng,
                      experience: result[k].trainer_experience,
                      start_time: result[k].datetime,
                      first_name: result[k].trainer_first_name,
                      last_name: result[k].trainer_last_name
                    }
                  ]
                })
              }
              let dataObject = {
                'completedstudyhallsduplicate': completedstudyhallsduplicate,
                'completedstudyhalls': completedstudyhalls,
                'traineesessions': result,
              }
              store.dispatch('studentdashboard/saveListTraineeSessions', dataObject)
              return {
                  'status': true,
                  'code': 200,
                  'data': dataObject
              }
            }
        })
        .catch((err) => {
          console.log('Error session: ', err)
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async reserveSessionForStudent (payload) {
    return axios.post(api.TRAINEE_SESSION, payload)
    .then(response => {
      if (response.data.data.limit_exceeds === true) {
        return {
          'status': response.status,
          'data': response.data.data,
          'limit_exceeds': response.data.data.limit_exceeds
        }
      } else {
        return {
          'status': response.status,
          'data': response.data.data,
        }
      }
    }).catch(err => {
      console.log('err: ', err)
      if (err.response.status === 400 && err.response.data.data.already_booked) {
        return {
          'status': 400,
          'data': err.response.data.data, 
          'already_booked': err.response.data.data.already_booked
        }
      }
      if ((err.response.data.data === 'Please recharge your wallet...') && err.response.status === 307) {
        return {
          'status': 307,
          'data': 'Please recharge your wallet.'
        }
      }
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 400) {
        return {
          'status': err.response.status,
          'data': err.response.data
        }
      } else if (err.response.status === 500) {
        console.log(err)
      }
    })
  },
  async addCreditToUser(payload) {
    return axios
        .get(`${api.ADDCREDIT}?amount=${payload}`)
        .then((response) => {          
          if (response.data.url !== '') {
            return {
                'status': true,
                'code': 200,
                'data': response.data.url
            }
          }
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchSubjectsForFindStudyHall (page, result = []) {
    return axios
        .get(`${api.BUSINESSGROUP}?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push({ value: val.id, text: val.name })
            })
            if (resp.data.next !== null) {
              this.fetchSubjectsForFindStudyHall(page+1, result)
            } else {
              store.dispatch('studentfindstudyhall/saveListSubjects', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchAllStudyhallForLandingPage () {
    return axios
        .get(`${api.STUDYHALLWITHSUBJECT}`)
        .then((resp) => {
          return {
              'status': true,
              'code': 200,
              'data': resp.data.data
          }  
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchAllStudyhallForFSP () {
    return axios
        .get(`${api.STUDYHALLWITHSUBJECT}`)
        .then((resp) => {
          return {
              'status': true,
              'code': 200,
              'data': resp.data.data
          }  
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudyhallByID (id) {
    return axios
        .get(`${api.CREATE_STUDYHALL}${id}/`)
        .then((resp) => {
          if (resp.status === 200) {
            return {
                'status': true,
                'code': 200,
                'data': resp.data
            }
          }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudyhallBySubjectForLandingPage (subject) {
    return axios
        .get(`${api.CREATE_STUDYHALL}?subject=${subject}`)
        .then((resp) => {
          return {
              'status': true,
              'code': 200,
              'data': resp.data.results
          }  
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async fetchStudyhallBySubjectForFSP (subject) {
    return axios
        .get(`${api.CREATE_STUDYHALL}?subject=${subject}`)
        .then((resp) => {
          return {
              'status': true,
              'code': 200,
              'data': resp.data.results
          }  
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async unSubscribeStudyHall (payload) {
    return axios.delete(`${api.SUBSCRIBE}${payload}/`)
    .then(response => {
      console.log('response: ', response)
      return {
        'status': true,
        'code': 200,
        'data': response.data
      }
    }).catch(err => {

      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.data.data === 'Please recharge your wallet...' && err.response.status === 307) {
        return {
          'status': false,
          'code': 307,
          'data': 'Please buy credits.'
        }
      } else if (err.response.status === 500) {
        console.log(err)
      } 
    })
  },
  async subscribeStudyHall (payload) {
    return axios.post(api.SUBSCRIBE, payload)
    .then(response => {
      console.log('response: ', response)
      return {
        'status': true,
        'code': 200,
        'data': response.data
      }
      // if (response.data.data.limit_exceeds !== undefined && response.data.data.limit_exceeds === true) {
      //   return {
      //     'status': true,
      //     'code': 200,
      //     'data': response.data.data,
      //     'limit_exceeds': response.data.data.limit_exceeds
      //   }
      // } else {
      //   return {
      //     'status': true,
      //     'code': 200,
      //     'data': response.data,
      //     'limit_exceeds': false
      //   }
      // }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.data.data === 'Please recharge your wallet...' && err.response.status === 307) {
        return {
          'status': false,
          'code': 307,
          'data': 'Please buy credits.'
        }
      } else if (err.response.data.data.already_subscribed === true && err.response.status === 400) {
        return {
          'status': false,
          'code': 400,
          'data': 'already_subscribed'
        }
      } else if (err.response.status === 500) {
        console.log(err)
      } 
    })
  },
  async subscribeStudyHallUpdate (data) {
    return axios.post(`${api.SUBSCRIBE}${data.subscribe_id}/`, data.payload)
    .then(response => {
      return {
        'status': true,
        'code': 200,
        'data': response.data
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.data.data === 'Please recharge your wallet...' && err.response.status === 307) {
        return {
          'status': false,
          'code': 307,
          'data': 'Please buy credits.'
        }
      } else if (err.response.status === 500) {
        console.log(err)
      }
    })
  },
  async saveRatings (payload) {
    return axios.post(api.saveRatings, payload)
    .then(response => {
      if (response.status === 201) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      }
    })
  },
  async saveSessionInformation (id, payload) {
    return axios.post(`${api.saveSessionInformation}${id}/`, payload)
    .then(response => {
      if (response.status === 200) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      }
    })
  },
  async fetchUsersOfOrganization (id, page, result = []) {
    return axios
        .get(`${api.organizationUserList}${id}/?page=${page}`)
        .then((resp) => {
            resp.data.results.map((val) => {
              result.push(val)
            })
            if (resp.data.next !== null) {
              this.fetchUsersOfOrganization(id, page+1, result)
            } else {
              store.dispatch('organizationusers/saveOrganizationUser', result)
              return {
                  'status': true,
                  'code': 200,
                  'data': result,
                  'count': resp.data.count
              }
            }
            
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async saveGuestStudyHallData(data) {
    await store.dispatch('landingpage/saveStudyhall', data)
  },
  async fetchSubscriptionPlans () {
    return axios
        .get(`${api.PLANS}`)
        .then((resp) => {
            console.log("Subscription Plans: ", resp.data.data)
            return {
                'status': true,
                'code': 200,
                'data': resp.data.data
            }
        })
        .catch((err) => {
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async updateSubscriptionPaypal (id, payload) {
    return axios
        .put(`${api.CREATESUBSCRIPTIONPAYPAL}${id}/`, {
          params: {
            amount: payload.amount,
            credit_score: payload.credit_score
          }
        })
        .then((resp) => {
          console.log("resp: ", resp.data)
            return {
                'status': true,
                'code': 200,
                'data': resp.data
            }
        })
        .catch((err) => {
          console.log("err: ", err.response.data)
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 400) {
                return {
                    'status': false,
                    'code': 400,
                    'data': err.response.data
                }
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async triggerTrackdesk (payload) {
    return axios
        .post(`${TRACKDESK_URL}`, payload)
        .then((resp) => {
          console.log("resp: ", resp)
            return {
                'status': true,
                'code': 200,
                'resp': resp
            }
        })
        .catch((err) => {
          console.log("err: ", err)
        })
  },
  async createSubscriptionPaypal (id, payload) {
    return axios
        .get(`${api.CREATESUBSCRIPTIONPAYPAL}${id}/`, {
          params: {
            amount: payload.amount,
            credit_score: payload.credit_score
          }
        })
        .then((resp) => {
          console.log("resp: ", resp.data)
            return {
                'status': true,
                'code': 200,
                'data': resp.data
            }
        })
        .catch((err) => {
          console.log("err: ", err.response.data)
            if (err.message === 'Network Error' || err.response.status === 401) {
                store.dispatch('auth/signOut')
                  .then(() => router.push('/signin'))
                  .catch(err => console.log(err))
              } else if (err.response.status === 400) {
                return {
                    'status': false,
                    'code': 400,
                    'data': err.response.data
                }
              } else if (err.response.status === 500) {
                console.log(err)
              }
        })
  },
  async subscriptionSaveAndPay (payload) {
    return axios.post(`${api.SUBSCRIPTION}`, payload)
    .then(response => {
      if (response.status === 200) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      } else if (err.response.status === 400) {
        return {
          'status': false,
          'code': 400,
          'data': err.response.data
        }
      }
    })
  },
  async getSubscription (id) {
    return axios.get(`${api.SUBSCRIPTION}${id}/`)
    .then(response => {
      if (response.status === 200) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      } else if (err.response.status === 400) {
        return {
          'status': false,
          'code': 400,
          'data': err.response.data
        }
      }
    })
  },
  async getAllSaveCards () {
    return axios.get(`${api.SAVECARDS}`)
    .then(response => {
      if (response.status === 200) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      } else if (err.response.status === 400) {
        return {
          'status': false,
          'code': 400,
          'data': err.response.data
        }
      }
    })
  },
  async planUpdate (payload, id) {
    return axios.put(`${api.SUBSCRIPTION}${id}/`, payload)
    .then(response => {
      if (response.status === 200) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      } else if (err.response.status === 400) {
        return {
          'status': false,
          'code': 400,
          'data': err.response.data
        }
      }
    })
  },
  async getPlanDetails () {
    return axios.get(`${api.DETAILSSCRIPTIONPAYPAL}`)
    .then(response => {
      if (response.status === 200) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      } else if (err.response.status === 400) {
        return {
          'status': false,
          'code': 400,
          'data': err.response.data
        }
      }
    })
  },
  async removePlan () {
    return axios.get(`${api.CANCELSCRIPTIONPAYPAL}`)
    .then(response => {
      if (response.status === 200) {
        return {
          'status': true,
          'code': 200,
          'data': response.data
        }
      } else {
        return {
          'status': false,
          'code': 400,
          'data': 'Failed'
        }
      }
    }).catch(err => {
      if (err.message === 'Network Error' || err.response.status === 401) {
        store.dispatch('auth/signOut')
          .then(() => router.push('/signin'))
          .catch(err => console.log(err))
      } else if (err.response.status === 500) {
        console.log(err)
      } else if (err.response.status === 400) {
        return {
          'status': false,
          'code': 400,
          'data': err.response.data
        }
      }
    })
  },
}
