import store from '..'
import axios from 'axios'
import api from '../../helpers/endpoints'


const state = {
    schools: null,
}

const getters = {
    getSchools (state) {
        return state.schools
    }
}

const actions = {
    async saveSchools ({commit}, payload) {
        commit('SET_SCHOOLS', payload)
    },
    async addSchool (_, payload) {
        return axios.post(api.MODULE, payload).then((response) => {
            return {
                'status': true,
                'code': 200,
                'data': response
            }
          }).catch(err => {
            if (err.message === 'Network Error' || err.response.status === 401) {
              store.dispatch('auth/signOut')
                .then(() => this.$router.push('/signin'))
                .catch(err => console.log(err))
            } else if (err.response.status === 500) {
              console.log(err)
            }
            if (err.response.status === 400) {
              return {
                  'status': false,
                  'code': 400,
                  'data': err.response.data
              }
            }
          })
    },
    async updateSchool (_, payload) {
        return axios.put(`${api.MODULE}${payload.id}/`, payload.name).then((response) => {
            return {
                'status': true,
                'code': 200,
                'data': response
            }
          }).catch(err => {
            if (err.message === 'Network Error' || err.response.status === 401) {
              store.dispatch('auth/signOut')
                .then(() => this.$router.push('/signin'))
                .catch(err => console.log(err))
            } else if (err.response.status === 500) {
              console.log(err)
            }
          })
    },
    async removeSchool (_, payload) {
        return axios.delete(`${api.MODULE}${payload}/`).then((response) => {
          return {
            'status': 204,
            'data': response.data
          }
        }).catch(err => {
          if (err.message === 'Network Error' || err.response.status === 401) {
            store.dispatch('auth/signOut')
              .then(() => this.$router.push('/signin'))
              .catch(err => console.log(err))
          } else if (err.response.status === 500) {
            console.log(err)
          }
        })
      },
    async destroySchoolStats ({commit}) {
        commit('RESET_SCHOOLS')
    }
}

const mutations = {
    SET_SCHOOLS (state, payload) {
        state.schools = payload
    },
    RESET_SCHOOLS (state) {
        state.schools = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }