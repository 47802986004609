// import store from '../../store'
// import axios from 'axios'
// import api from '../../helpers/endpoints'


const state = {
    listSubjects: null
}

const getters = {
    getListSubjects (state) {
        return state.listSubjects
    },
}

const actions = {
    async saveListSubjects ({commit}, payload) {
        commit('SET_LIST_Subjects', payload)
    },
    async destroyFindStudyHallStats ({commit}) {
        commit('RESET_FINDSTUDYHALL')
    }
}

const mutations = {
    SET_LIST_Subjects (state, payload) {
        state.listSubjects = payload
    },
    RESET_FINDSTUDYHALL (state) {
        state.listSubjects = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }